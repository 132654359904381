import { getGatsbyImageData } from "@imgix/gatsby"
import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyAllStories } from "../../../slices/PageDataBodyAllStories"

type Post = React.ComponentPropsWithoutRef<
	typeof PageDataBodyAllStories
>["posts"][number]

const getPostImage = (data: { src: string; aspectRatio: number }) => {
	return getGatsbyImageData({
		width: 800,
		layout: "fullWidth",
		breakpoints: [320, 640, 800],
		...data,
	})
}

const post = (data: Partial<Post> = {}): Post => {
	return {
		href: "#",
		image: getPostImage({
			src: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
			aspectRatio: 1513 / 1008,
		}),
		imageAlt: "",
		summary: [
			{
				type: "paragraph",
				spans: [],
				text: lorem.sentences(1),
			},
		] as pt.RichTextField,
		title: lorem.words(2),
		publishedAt: new Date().toDateString(),
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyAllStories
				indexProps={{
					heading: lorem.words(5),
					copy: [
						{ text: lorem.sentences(2), type: "paragraph", spans: [] },
					] as pt.RichTextField,
				}}
				posts={[
					post(),
					post({
						image: getPostImage({
							src: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2",
							aspectRatio: 673 / 1008,
						}),
					}),
					post({
						image: getPostImage({
							src: "https://images.unsplash.com/photo-1508243529287-e21914733111",
							aspectRatio: 800 / 533,
						}),
					}),
				]}
				currentPage={1}
				totalPages={3}
			/>
		</CmsGuideLayout>
	)
}

export default Component
